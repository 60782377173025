.mapboxgl-popup-content {
  padding: 0;
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: none;
}

.mapboxgl-popup-tip {
   display: none;
  }

